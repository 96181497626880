nav{
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100vw;
    padding: 0px 80px;
    transition: 0.25s all ease-in-out;
    z-index: 4;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    opacity: 0.95;
}

.navElement{
    height: 80px;
}

nav ul{
    display: flex;
    list-style-type: none;
    height: 100%;
    align-items: center;
}

nav ul li{
    margin: 10px;
}

nav ul li a{
    display: block;
    position: relative;
    text-decoration: none;
    color: inherit;
    width: fit-content;
    font-weight: 500;   
}

nav ul li a::before{
    position: absolute;
    content: "";
    left: 20%;
    bottom: -4px;
    width: 0px;
    height: 2px;
    backdrop-filter: invert(1);
    transition: 0.2s all ease-in-out;
    z-index: 1;
}

nav ul li a:hover::before{
    height: 2.2px;
    left: 0%;
    width: calc(100% + 5px);
}

#nav_trigger{
    display: none;
}

#nav_trigger_trigger{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.2s all ease-in-out;
}

#nav_trigger_trigger:hover{
    filter: invert(0.8);
}

#nav_trigger_trigger{
    display: none;
}

@media only screen and (max-width:1100px)  {
    #nav_options{
        flex-direction: column;
        position: absolute;
        align-items: center;
        justify-content: space-evenly;
        left: 100%;
        top: 80px;
        width: 100%;
        height: calc(100vh - 80px);
        background: rgba(35, 35, 46, 0.95);
        transition: 0.5s all ease-in-out;  
    }

    #nav_trigger_trigger{
        display: block;
    }

    #nav_options li a{
        color: white;
    }
    
    #nav_trigger:checked+ul{
        left: 0%;
    }
    .menuitem{
        color: white;
    }
}

@media only screen and (max-width:500px)  {
    nav{
        padding: 0px 10vw;
    }
}