section{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100vw;
    min-height: 100vh;
    padding: 50px;
}

section h1{
    text-align: center;
    font-size: 2.5rem;
}

p{
    font-size: 1.3rem;
}

@media only screen and (max-width:1100px)  {
    section h1{
        font-size: 2rem;
    }
}

@media only screen and (max-width:600px)  {
    section h1{
        font-size: 1.5rem;
    }
}

#home{
    background: url('../componentDependencies/HomePageScreen1.jpg');
    background-size: cover;
}

#contact{
    background: url('../componentDependencies/WorldMap.jpg');
    background-size: cover;
}

#events{
    background: url('../componentDependencies/EventsBackground.jpg');
    background-size: contain;
    background-attachment: fixed;
}

#magazine{
    background: url('../componentDependencies/ELevateBackground.jpg');
    background-size: cover;
}