footer{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  transition: 0.25s all ease-in-out;
  padding: 20px 10px;
}

.footerblocks{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerblocks h3{
  font-size: 1.4rem;
  font-weight: 900;
  margin: 5px 0px;
}

.footerblocks ul li{
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 10px 0px;
  font-weight: 500;
}

.footerblocks ul li i{
  margin: 0px 10px;
}

.footerblocks li a{
  position: relative;
  text-decoration: none;
}

.footerblocks li a::before{
  position: absolute;
  content: '';
  width: 0px;
  height: 2.2px;
  bottom: -3px;
  left: 20%;
  transition: all 0.2s ease-in-out;
  backdrop-filter: invert(1);
}

.footerblocks li a:hover::before{
  left: -2px;
  height: 2.5px;
  width: calc(100% + 4px);
}

#collegeLogo img{
  position: static;
}

#community .fa{
  background: rgb(60, 60, 60);
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 15px;
  font-size: 1.4rem;
  border-radius: 10px;
  transition: 0.1s all ease-in-out;
}

#community .fa:hover{
  background: rgb(30, 30, 30);
}

.footerblocks#community a:nth-child(1){
    background: rgb(49, 49, 194);
}

.footerblocks#community a:nth-child(2){
    background: rgb(30, 30, 129);
}

.footerblocks#community a:nth-child(3){
    background: rgb(158, 23, 23);
}

.footerblocks#community a:nth-child(4){
    background: radial-gradient(farthest-corner at 0% 100%,rgb(255, 215, 56), rgb(235, 25, 64),rgb(148, 25, 148))
}

@media only screen and (max-width:900px)  {
  footer{
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 30px 0px;
  }
}

@media only screen and (max-width:500px)  {
  footer{
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
}