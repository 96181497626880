.carousel_container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  min-width: 375px;
  max-width: 700px;
  border-radius: 10px;
  border: none;
}

.video-container{
 border-radius: 10px;
 width: 100%;
 display: flex; 
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.video-container video{
  width: 100%;
}