.sectionContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 80vw;
    min-height: 80vh;
    margin-top: 2vw;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.picNpara {
    display: grid;
    grid-template-columns: 2fr 3fr;
    padding: 20px;
    grid-gap: 20px;
    border-radius: 20px;
    margin: 20px;
    backdrop-filter: blur(5px);
}

.picNpara img {
    width: 100%;
    border-radius: 10px;
}

.picNpara p {
    font-size: 1.2rem;
}

@media only screen and (max-width:1000px) {
    .sectionContainer {
        margin-top: 10vw;
    }

    .sectionContainer h1 {
        margin: 0px 0px 50px 0px;
    }

    section .picNpara:nth-child(even) {
        grid-template-columns: 1fr;
    }

    section .picNpara:nth-child(odd) {
        grid-template-columns: 1fr;
    }

    .picNpara img {
        width: 40vw;
        min-width: 300px;
        margin: 10px auto;
    }

    .picNpara p {
        margin: 5px;
        text-align: center;
    }
}

.curveContainer {
    position: relative;
}

.magazine-container{
    display: flex;
    width: 100%;
}

.magazine-container:nth-child(even){
    justify-content: right;
}

.magazine-sub-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
    padding: 20px;
    grid-gap: 20px;
    border-radius: 20px;
    margin: 20px;
    text-shadow: 2px 2px 1px rgba(0,0,0,0.75);
}

.magazine-sub-container::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: -1;
    filter: blur(10px);
}

.bottomWave, .topWave{
    position: absolute;
    width: 100%;
    left: 0px;
}

.topWave{
    background: url('../componentDependencies/wave1.png');
    background-size: contain;
    background-repeat: repeat-x;
    top: -1px;
    height: 50px;
    animation: wave linear infinite;
}

.bottomWave{
    background: url('../componentDependencies/wave2.png');
    background-size: 1000px 100%;
    background-repeat: repeat-x;
    background-position-y: bottom;
    bottom: -1px;
    height: 70px;
    animation: wave infinite linear;
}

@keyframes wave {
    0%{
        background-position-x: 0px;
    }
    100%{

        background-position-x: -1000px;
    }
}