.teamMember{
  position: relative;
  height: 250px;
  width: 250px;
  margin: 10px;
}

.teamContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.memberContainer{
  position: absolute;
  left: 0px; top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.2s all ease-in-out;
  clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
}

.memberDetails{
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;

  padding: 20px;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: 0.2s all ease-in-out;
  background: rgba(0,0,0,0.5);
}

.memberDetails h4{
  font-size: 1.3rem;
  text-align: center;
  margin: 5px 0px;
}

.memberDetails p{
  text-align: center;
  font-size: 1.1rem;
  margin: 3px 0px;
}

.memberContainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0px 0px;
}

.memberContainer:hover{
  left: -15%;
  top: -15%;
  width: 130%;
  height: 130%;
  clip-path: polygon(75% 7%, 100% 50%, 75% 93%, 25% 93%, 0% 50%, 25% 7%);
  z-index: 2;
}

.memberContainer:hover .memberDetails{
  opacity: 1;
  filter: none;
}