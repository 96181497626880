.formBar{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px 0px;
}

.formBar label{
  padding: 5px;
  font-weight: 600;
}

.formBar input, .formBar textarea{
  padding: 5px 10px;
  border-radius: 3px;
  border: none;
  font-weight: 500;
  width: 20vw;
}

.form button{
  margin: auto;
  padding: 7px 15px;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: .9rem;
  transition: 0.2s all ease-in-out;
}

.form textarea{
  height: 120px;
  resize: none;
}

.form button:hover{
  filter: invert(1);
}

@media only screen and (max-width:1100px)  {
  .formBar input, .formBar textarea{
    width: 300px;
  }
}

@media only screen and (max-width:750px)  {
  h1{
    margin-top: 50px;
  }
  .formBar {
    grid-template-columns: 1fr;
  }
  .formBar label{
    text-align: center;
  }
}